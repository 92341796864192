<template>
  <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-700">
    <div class="flex h-24 shrink-0 items-center px-6">
      <img
        alt="Batobs.org"
        class="h-8 w-auto"
        src="~/assets/images/logo.svg"
      >
    </div>
    <nav class="flex flex-1 flex-col justify-between">
      <ul
        class="space-y-2 px-4 pb-2"
        role="list"
      >
        <AppMenuItem
          v-for="item in nav"
          :key="item.name"
          :item="item"
        />
      </ul>
      <ul role="list">
        <li class="mb-8 px-4">
          <UiButton
            class="w-full"
            @click="signOut"
          >
            <UiIcon name="heroicons:arrow-right-on-rectangle" />
            Log out
          </UiButton>
        </li>
        <li v-if="user?.capabilities.includes('project.createOrUpdateObservation')">
          <button
            class="group flex h-40 w-full flex-col items-center justify-center bg-indigo-800 text-sm leading-6 text-gray-900 transition-all duration-100 hover:bg-indigo-200"
            @click="observeNow"
          >
            <span
              aria-hidden="true"
              class="text-xl font-semibold text-white group-hover:text-indigo-900"
            >Observe now!</span>
            <span
              aria-hidden="true"
              class="text-base font-normal text-indigo-400 group-hover:text-indigo-600"
            >Click here to start</span>
          </button>
        </li>
      </ul>
    </nav>
    <div
      v-if="release"
      class="pointer-events-none absolute bottom-0.5 left-0.5 text-sm text-indigo-200/10"
    >
      {{ 'Version ' + release.substring(0, 6) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {useResponsiveMenu} from '~/composables/responsive-menu';

const {public: {release}} = useRuntimeConfig();

const {close} = useResponsiveMenu();
const {signOut} = useSession();
const router = useRouter();
const user = useSofieUserWhenAuthenticated();

export type MenuItem = {
  name: string,
  icon: string,
  to: string | null,
  current: Ref<boolean>
};

const nav: MenuItem[] = [
  {
    name: 'Dashboard',
    to: '/',
    icon: 'heroicons:home',
    current: ref(true),
  },
  {
    name: 'Visits',
    to: '/visits',
    icon: 'heroicons:map-pin',
    current: ref(false),
  },
  {
    name: 'Observations',
    to: '/observations',
    icon: 'heroicons:eye',
    current: ref(false),
  },
  ...(user.value?.capabilities.includes('project.read') ? [{
    name: 'Projects',
    to: '/projects',
    icon: 'heroicons:clipboard',
    current: ref(false),
  }] : []),
  ...(user.value?.capabilities.includes('formversion.read') ? [{
    name: 'Forms',
    to: '/forms',
    icon: 'heroicons:adjustments-horizontal',
    current: ref(false),
  }] : []),
];

// on mobile run once onCreate hook when menu gets created i.e. opening on mobile
const currentPath = router.currentRoute.value.fullPath.match(/^\/(\w+)/gm) ?? ['/'];
nav.forEach((item) => {
  item.to === currentPath[0] ? item.current.value = true : item.current.value = false;
});

router.afterEach((guard) => {
  const currentPath = guard.fullPath.match(/^\/(\w+)/gm) ?? ['/'];
  nav.forEach((item) => {
    item.to === currentPath[0] ? item.current.value = true : item.current.value = false;
  });
  close();
});

function observeNow() {
  useObservation().open();
}

</script>
