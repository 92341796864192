<template>
  <div class="grid h-full lg:grid-cols-[288px_minmax(0,_1fr)]">
    <AppMenu
      class="max-lg:hidden"
    />

    <AppMenuMobile
      class="lg:hidden"
    />

    <slot />

    <ObservationsCreate
      v-if="user"
      v-model:open="openModal"
      @close="openModal = false"
    />
  </div>
</template>

<script lang="ts" setup>
const {isOpen: openModal} = useObservation();

const user = useSofieUserWhenAuthenticated();
</script>
