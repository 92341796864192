<template>
  <UiModal
    :open="open"
    :title="currentObservation ? 'Edit observation' : 'New observation'"
    @submit="submitForm"
    @close="closeForm"
  >
    <FormKit
      ref="observationForm"
      v-model="observationCreateForm"
      type="form"
      :actions="false"
      @submit="saveObservation"
    >
      <FormKit
        type="hidden"
        name="observation"
      />
      <FormKit
        v-if="currentObservation && locationEditEnabled || !currentObservation"
        type="group"
      >
        <div class="mb-8 h-72">
          <PdokMap
            :allowed="['point']"
            @set-marking="setCoordinates"
          />
          <ul
            v-if="showGeomError"
            class="formkit-messages mb-0 mt-1 list-none p-0"
          >
            <li class="formkit-message mb-1 text-xs text-red-500">
              Observation location is required.
            </li>
          </ul>
        </div>
      </FormKit>
      <UiButton
        v-else
        label="Change location"
        class="mb-8"
        @click="locationEditEnabled = true"
      />
      <FormKit
        name="project"
        label="Project"
        type="select"
        :options="projects"
        validation="required"
      />
      <FormKit
        name="capture"
        label="Bat"
        type="select"
        :options="captures"
        validation="required"
      />
      <div class="grid grid-cols-2 gap-x-6">
        <FormKit
          label="Date start"
          name="date_start"
          type="date"
          validation="required"
        />
        <FormKit
          label="Time"
          name="time_start"
          type="time"
          validation="required"
        />
        <FormKit
          label="Date end"
          name="date_end"
          type="date"
        />
        <FormKit
          label="Time end"
          name="time_end"
          type="time"
        />
      </div>
      <FormKit
        accept=".jpg,.png,.jpeg,.gif"
        label="Add photo"
        name="photo"
        type="uploadFile"
      />
      <div
        v-if="currentObservation?.images"
        class="mb-6 grid grid-cols-2 gap-6"
      >
        <img
          v-for="image in currentObservation?.images"
          :key="image.id"
          :src="image.url"
          :alt="image.title"
          class="object-fit col-span-1 h-full rounded-xl"
        >
      </div>
      <FormKit
        name="activity"
        label="Activity"
        type="select"
        :options="activityTypes"
      />
      <FormKit
        name="roost"
        label="Roost"
        type="select"
        :options="roostTypes"
      />
      <FormKit
        name="biotope"
        label="Biotope"
        type="select"
        :options="biotopeTypes"
      />
      <FormKit
        name="accuracy"
        label="Accuracy (in meter)"
        type="number"
      />
    </FormKit>

    <UiModal
      ref="successModal"
      v-model:open="success"
      title="Observation ended"
      :actions="false"
      @update:open="open = $event"
    >
      <h4 class="text-base font-semibold text-gray-900">
        Thank you for your input!
      </h4>
      <!--
      <p class="font-regular pt-2 text-xs text-gray-500">
        Ut urna at non nullam volutpat suspendisse. Sapien lacus purus malesuada nisi netus sit aliquet gravida.
        Pellentesque cras habitant ornare tellus. In et libero.
      </p>
      -->
      <hr class="my-10">
      <div class="flex flex-row gap-x-3">
        <UiButton
          class="w-max py-10 text-sm font-semibold !text-white"
          label="Back to home"
          @click="returnHome"
        />
        <UiButton
          class="w-max !bg-indigo-50 text-sm font-semibold !text-indigo-600"
          label="New observation"
          @click="observeNow"
        />
      </div>
    </UiModal>
  </UiModal>
</template>

<script setup lang="ts">
import {useCapturesQuery, useObservationOptionsQuery, useProjectsQuery} from '~/graphql/graphql';
import updateOrCreateObservation from '~/graphql/mutations/observations/updateOrCreateObservation.gql';
import {DateTime} from 'luxon';
import {evictApolloCache} from '~/utils/apollo';

const currentDate = DateTime.now().toISODate();
const currentTime = DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE);

const {currentObservation, close} = useObservation();

const props = defineProps<{
  open: boolean
}>();

const emit = defineEmits<{
  (e: 'update:open'): void
}>();

const locationEditEnabled = ref(false);
const open = useVModel(props, 'open', emit);
const observationState = useLocalStorage('observation_state', {project: null, bat: null});

watch(open, (value) => {
  if (!value) {
    close();
    locationEditEnabled.value = false;
  }
});

// Options
const {result: optionsResult} = useObservationOptionsQuery({
  pollInterval: process.client && 600_000, // 10 minutes
});
const {map: mapToOptions} = useOptions();

const biotopeTypes = computed(() => mapToOptions(optionsResult.value?.biotopeTypes ?? []));
const activityTypes = computed(() => mapToOptions(optionsResult.value?.activityTypes ?? []));
const roostTypes = computed(() => mapToOptions(optionsResult.value?.roostTypes ?? []));

// Projects
const {result: projectsQueryResult} = useProjectsQuery();
const projects = computed(() => mapToOptions(projectsQueryResult.value?.projects ?? []));

// Submit observation
const observationForm = ref();
const observationCreateForm = ref({});
const showGeomError = ref(false);

const successModal = ref();

watch(currentObservation, (currentObservation) => {
  if (!currentObservation) {
    return observationCreateForm.value = {
      project: observationState.value.project,
      time_start: currentTime,
      date_start: currentDate,
    };
  }

  observationCreateForm.value = {
    accuracy: currentObservation.accuracy,
    activity: currentObservation.activity?.id,
    biotope: currentObservation.biotope?.id,
    capture: currentObservation.capture?.id,
    date_end: currentObservation?.end ? DateTime.fromJSDate(new Date(currentObservation.end)).toISODate() : undefined,
    date_start: currentObservation.start ? DateTime.fromJSDate(new Date(currentObservation.start)).toISODate() : currentDate,
    observation: currentObservation.id,
    project: currentObservation.project?.id ?? observationState.value.project,
    roost: currentObservation.roost?.id,
    time_end: currentObservation.end ? DateTime.fromJSDate(new Date(currentObservation.end)).toISOTime({includeOffset: false}) : undefined,
    time_start: currentObservation.start ? DateTime.fromJSDate(new Date(currentObservation.start)).toISOTime({includeOffset: false}) : currentTime,
  };
});

const {mutate, onDone, onError, validationErrors} = useSofieMutation(updateOrCreateObservation);

const success = ref(false);
onDone(() => {
  evictApolloCache('observations');
  success.value = true;
});

function observeNow() {
  success.value = false;
  observationForm.value.node.reset();
}

function closeForm() {
  observationForm.value.node.reset();
  evictApolloCache('observations');
}

function returnHome() {
  useRouter().push('/');
  successModal.value.close();
}

onError(() => {
  if (validationErrors.value.length) {
    toast.error('Please fill in all fields correctly.');
  } else {
    toast.error('An error has occurred.');
  }
});

function submitForm() {
  observationForm.value.node.submit();
}

// Map
const geom = ref<number[] | undefined>(undefined);

function setCoordinates({geometry}) {
  showGeomError.value = false;
  geom.value = geometry.coordinates;
}

// Project
const captureVariables = computed(() => ({project: observationCreateForm.value?.project}));
const hasProject = computed(() => !!observationCreateForm.value?.project);

const {result: queryResult} = useCapturesQuery(captureVariables, {
  enabled: hasProject,
  pollInterval: process.client && 600_000, // 10 minutes
});
const captures = computed(() => mapToOptions(queryResult.value?.captures ?? []));

async function saveObservation() {
  if (!geom.value && !currentObservation.value) {
    showGeomError.value = true;
    return;
  }

  const formData = {
    ...observationCreateForm.value,
  };

  await mutate({
    ...formData,
    geom: geom.value,
    accuracy: parseInt(formData.accuracy) ?? null,
  });

  observationState.value = {
    project: observationCreateForm.value.project,
    bat: observationCreateForm.value.capture,
  };
}
</script>
